import * as React from 'react';
import BaseLayout from '../components/Layouts/BaseLayout';
import { Container } from 'reactstrap';
import * as cvJson from '../data/cv.json';
import Markdown from '../components/Markdown/Markdown';

export const CvPageInternal = ({ cvMarkdown, location }) => {
  return (
    <BaseLayout location={location} title="CV">
      <header className="page-header small bg-secondary">
        <Container>
          <div className="header-content text-center">
            <h1 className="header-title bg-transparent">CV</h1>
          </div>
        </Container>
      </header>

      <section className="big-section">
        <Container>
          <Markdown source={cvMarkdown} />
        </Container>
      </section>
    </BaseLayout>
  );
};

export default ({ location }) => <CvPageInternal location={location} {...cvJson} />;
